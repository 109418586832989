:root {
    --primary-background-color: var(--rain-white);
    --secondary-background-color: var(--rain-ink-025);
    --tertiary-background-color: var(--rain-ink-050);
    --unavailable-border-color: #f4f4f9;
    --option-grey-background-color: #f9f9fe;
    --option-grey-border-color: #a2a6b3;
    --option-grey-color: #6b7580;
    --option-duration-color: #c7c8d1;
    --option-prefix-background-color: #f0f1f5;
    --error-background-color: #fceced;
    --error-color: #c33556;

    --primary-date-picker-color: var(--gt-purple-100);
    --primary-text-color: #ffffff;

    --gt-cobalt-3: #1c7dfc;

    --gt-ink-900: #111827;
    --gt-ink-700: #41454e;
    --gt-ink-500: #6b7280;
    --gt-ink-300: #a2a6b3;
    --gt-ink-100: #dcdee4;
    --gt-ink-025: #f0f1f5;
    --gt-ink-010: #f9f9fe;
    --gt-teal-500: #17d9af;
    --gt-teal-025: #d9fffa;

    --ink-900: #1c1d21;
    --ink-700: #41454e;
    --ink-500: #757b8d;
    --ink-300: #e3e5ec;
    --ink-200: #c7c8d1;
    --ink-100: #d0d2db;
}
