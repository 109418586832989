:root {
    --spacing-01: 0.125rem; // 2px
    --spacing-02: 0.25rem; // 4px
    --spacing-03: 0.5rem; // 8px
    --spacing-04: 0.75rem; // 12px
    --spacing-05: 1rem; // 16px
    --spacing-06: 1.5rem; // 24px
    --spacing-07: 2rem; // 32px
    --spacing-08: 2.5rem; // 40px
    --spacing-09: 3rem; // 48px
    --spacing-10: 4rem; // 64px
    --spacing-11: 5rem; // 80px
    --spacing-12: 6rem; // 96px
    --spacing-13: 10rem; // 160px

    --spacing-3xs: var(--spacing-01);
    --spacing-2xs: var(--spacing-02);
    --spacing-xs: var(--spacing-03);
    --spacing-sm: var(--spacing-04);
    --spacing-md: var(--spacing-05);
    --spacing-lg: var(--spacing-06);
    --spacing-xl: var(--spacing-07);
    --spacing-2xl: var(--spacing-08);
    --spacing-3xl: var(--spacing-09);
    --spacing-4xl: var(--spacing-10);
    --spacing-5xl: var(--spacing-11);
    --spacing-6xl: var(--spacing-12);
}
