.container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: black;
    background-repeat: no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    z-index: 1;
    height: 100%;
    overflow-y: scroll;
    .loadingText {
        font-style: normal;
        font-weight: 700;
        font-size: var(--font-size-04);
        color: white;
        text-align: center;
        padding: 0 16px;
    }

    .loadingContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        width: 300px;
        height: 180px;
        background: rgba(0, 0, 0, 0.65);
    }

    .loadingArea {
        display: inline-block;
        height: auto;
        border-radius: 20px;
        border: 2px solid transparent;
    }

    .tongue {
        width: 18px;
        height: var(--spacing-03);
        border-radius: 9px;
        background: var(--primary-background-color);
        position: absolute;
        right: var(--spacing-03);
        top: 26px;
        z-index: 1;
        animation-iteration-count: infinite;
        animation-name: pink_tongue;
        animation-timing-function: ease-in-out;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-duration: 6.5s;
        transform-origin: 18px 0px;
        z-index: 1;
        transform: rotate(0deg);
    }
    .tongue2 {
        width: var(--spacing-06);
        height: var(--spacing-03);
        border-radius: 9px;
        background: #ccc;
        position: absolute;
        right: var(--spacing-03);
        top: 26px;
        animation-iteration-count: infinite;
        animation-name: spin2;
        animation-timing-function: ease-in-out;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-duration: 6.5s;
        transform-origin: 0px 2px;
        z-index: 0;
        transform: rotate(0deg);
    }
    .logo {
        animation-timing-function: ease-in;
        transition: 0.5s;
        position: absolute;
        top: var(--spacing-03);
        left: 0;
        animation-duration: 6.5s;
        animation-name: bottom_logo;
        animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        opacity: 0;
        animation-fill-mode: forwards;
        opacity: 1;
        clip-path: circle(10% at 0 17%);
        width: var(--spacing-10);
        height: var(--spacing-10);
    }

    .logo2 {
        animation-timing-function: ease-in;
        transition: 0.5s;
        position: absolute;
        top: -10px;
        left: 0;
        transform: rotate(180deg);
        opacity: 0;
        animation-duration: 6.5s;
        animation-name: top_logo;
        animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        opacity: 0.5;
        clip-path: circle(10% at 0 17%);
        transform: scaleY(-1);
        width: var(--spacing-10);
        height: var(--spacing-10);
    }

    .spinner {
        display: block;
        width: var(--spacing-10);
        height: var(--spacing-10);
        line-height: 100%;
        animation-fill-mode: forwards;
        animation-duration: 0.25s;
        animation-name: spinner_pop_in;
        opacity: 0;
        top: 0px;
        left: 0px;
        border-radius: 50%;
        margin: 0 auto;
    }

    .loadingArea.loaded {
        animation-fill-mode: forwards;
        animation-duration: 0.25s;
        animation-name: spinner_pop_in;
    }

    @keyframes top_logo {
        28% {
            clip-path: circle(0% at 0 17%);
        }
        60% {
            clip-path: circle(120% at 0 17%);
        }
        70% {
            clip-path: circle(120% at 0 17%);
        }
        80% {
            clip-path: circle(0% at 0 17%);
        }
    }
    @keyframes bottom_logo {
        0% {
            clip-path: circle(0% at 0 17%);
        }
        7% {
            clip-path: circle(120% at 0 17%);
        }
        70% {
            clip-path: circle(120% at 0 17%);
        }
        80% {
            clip-path: circle(0% at 0 17%);
        }
    }
    @keyframes pop_out {
        10% {
            transform: scale(1);
            opacity: 1;
        }
        15% {
            transform: scale(1.5);
            opacity: 1;
        }
        20% {
            transform: scale(0);
            opacity: 0.25;
        }
        21% {
            transform: scale(0);
            opacity: 0;
        }
        80% {
            transform: scale(0);
            opacity: 0;
        }

        85% {
            transform: scale(1.5);
            opacity: 1;
        }
        90% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes pop_full {
        0% {
            transform: scale(0.8);
            opacity: 1;
        }
        50% {
            transform: scale(1.5);
            opacity: 1;
        }
        100% {
            transform: scale(0);
            opacity: 0.25;
        }
    }

    @keyframes spin2 {
        0% {
            transform: rotate(180deg);
            opacity: 0;
        }
        20% {
            transform: rotate(0deg);
            opacity: 0;
        }
        25% {
            transform: rotate(0deg);
            opacity: 1;
        }
        70% {
            transform: rotate(720deg);
            opacity: 1;
        }
        80% {
            transform: rotate(720deg);
            opacity: 0;
        }
    }
    @keyframes pink_tongue {
        0% {
            opacity: 0;
            transform: rotate(-90deg);
        }
        7% {
            opacity: 0;
            transform: rotate(-90deg);
        }
        9% {
            opacity: 1;
            transform: rotate(0deg);
        }
        70% {
            opacity: 1;
        }
        80% {
            opacity: 0;
        }
    }
    @keyframes spinner_pop_in {
        0% {
            transform: scale(0);
            opacity: 0;
        }
        50% {
            transform: scale(1.2);
            opacity: 0.8;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
}
.container::before {
    content: '';
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 1);
    opacity: 0.5;
    z-index: -1;
    position: fixed;
    top: 0;
    padding-right: 20px;
    left: 0;
}
