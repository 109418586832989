:root {
    --font-size-01: 0.75rem; // 12px
    --font-size-02: 0.875rem; // 14px
    --font-size-03: 1rem; // 16px
    --font-size-04: 1.125rem; // 18px
    --font-size-05: 1.25rem; // 20px
    --font-size-06: 1.5rem; // 24px
    --font-size-07: 1.75rem; // 28px
    --font-size-08: 2rem; // 32px
    --font-size-09: 2.25rem; // 36px
    --font-size-10: 2.625rem; // 42px
    --font-size-11: 3rem; // 48px
    --font-size-12: 3.375rem; // 54px
    --font-size-13: 3.75rem; // 60px
    --font-size-14: 4.25rem; // 68px
    --font-size-15: 4.75rem; // 76px
    --font-size-16: 5.25rem; // 84px
    --font-size-17: 5.75rem; // 92px
}
